import Slide1 from '../../images/slider/slide1.svg';
import Slide2 from '../../images/slider/slide2.svg';
import Slide3 from '../../images/slider/slide3.svg';
import Slide4 from '../../images/slider/slide4.svg';
import Slide5 from '../../images/slider/slide5.svg';
import Slide6 from '../../images/slider/slide6.svg';
import Slide7 from '../../images/slider/slide7.svg';
import Slide8 from '../../images/slider/slide8.svg';
import Slide9 from '../../images/slider/slide9.svg';
import Slide10 from '../../images/slider/slide10.png';
import logo from '../../images/logo1.png';
import logoFooter from '../../images/logo.png';

export const DATA_IMAGES = [
    Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7, Slide8,Slide9,Slide10,
]
export const LOGO = logo
export const LOGO_FOOTER = logoFooter