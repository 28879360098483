import React from 'react'
import { DETAILED_NEWS } from '../selectors/dataNews'
import { useLocation, Link } from 'react-router-dom'
import styles from './style.module.scss'
// import { useAuth0 } from '@auth0/auth0-react';
import ReviewsList from '../Main/Reviews/ReviewsList';
import Reviews from '../Main/Reviews/Reviews';

export default function BlogDetailed() {
  const location = useLocation()
  // const { isAuthenticated } = useAuth0()
  // console.log(isAuthenticated)
  return (
    <section className={styles.detailed}>
      <Link to='/blog' className={styles.arrow}>Backspace</Link>
      <div className="container">
        {DETAILED_NEWS.filter((item) => item.path.replace(/ /g, '').toLowerCase() === location.pathname.toLowerCase()).map((elem, index) => (
            <div key={index} className={styles.detailed__company}>
                <img className={styles.image} src={elem.image} alt={elem.name}  />
                <h2>{elem.heading}</h2>
                <p>{elem.fullDescription}</p>
                {elem?.descriptionLvl ? <>
                  <h2>{elem.descriptionLvl?.rev1?.heading}</h2>
                  <p>{elem.descriptionLvl?.rev1?.desc}</p>
                  <p>{elem.descriptionLvl?.rev1?.desc1}</p>
                  <p>{elem.descriptionLvl?.rev1?.desc2}</p>
                  <p>{elem.descriptionLvl?.rev1?.desc3}</p>
                  <p>{elem.descriptionLvl?.rev1?.desc4}</p>
                  <p>{elem.descriptionLvl?.rev1?.desc5}</p>
                  <p>{elem.descriptionLvl?.rev1?.desc6}</p>
                  <h2>{elem.descriptionLvl?.rev2?.heading}</h2>
                  <p>{elem.descriptionLvl?.rev2?.desc}</p>
                  <p>{elem.descriptionLvl?.rev2?.desc1}</p>
                  {elem?.descriptionLvl?.rev2?.ul ? 
                    <ul>
                      {!elem.descriptionLvl.rev2.ul.line1 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line1}</li>}
                      {!elem.descriptionLvl.rev2.ul.line2 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line2}</li>}
                      {!elem.descriptionLvl.rev2.ul.line3 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line3}</li>}
                      {!elem.descriptionLvl.rev2.ul.line4 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line4}</li>}
                      {!elem.descriptionLvl.rev2.ul.line5 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line5}</li>}
                      {!elem.descriptionLvl.rev2.ul.line6 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line6}</li>}
                      {!elem.descriptionLvl.rev2.ul.line7 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line7}</li>}
                      {!elem.descriptionLvl.rev2.ul.line8 ?  '' : <li>{elem?.descriptionLvl?.rev2?.ul?.line8}</li>}
                    </ul> : 
                    <p></p>
                  }
                  <p>{elem.descriptionLvl?.rev2?.desc2}</p>
                  <p>{elem.descriptionLvl?.rev2?.desc3}</p>
                  <p>{elem.descriptionLvl?.rev2?.desc4}</p>
                  <p>{elem.descriptionLvl?.rev2?.desc5}</p>
                  <p>{elem.descriptionLvl?.rev2?.desc6}</p>
                  {elem.descriptionLvl?.image ? <img src={elem.descriptionLvl?.image} style={{margin: '20px 0px'}} alt="img" /> : null}
                  {elem?.graph ? <img src={elem.graph} alt='graph' /> : null}
                  <h2>{elem.descriptionLvl?.rev3?.heading}</h2>
                  <p>{elem.descriptionLvl?.rev3?.desc}</p>
                  <p>{elem.descriptionLvl?.rev3?.desc1}</p>
                  <p>{elem.descriptionLvl?.rev3?.desc2}</p>
                  <p>{elem.descriptionLvl?.rev3?.desc3}</p>
                  <p>{elem.descriptionLvl?.rev3?.desc4}</p>
                  <p>{elem.descriptionLvl?.rev3?.desc5}</p>
                  <p>{elem.descriptionLvl?.rev3?.desc6}</p>
                  <h2>{elem.descriptionLvl?.rev4?.heading}</h2>
                  <p>{elem.descriptionLvl?.rev4?.desc}</p>
                  <p>{elem.descriptionLvl?.rev4?.desc1}</p>
                  <p>{elem.descriptionLvl?.rev4?.desc2}</p>
                  <p>{elem.descriptionLvl?.rev4?.desc3}</p>
                  <p>{elem.descriptionLvl?.rev4?.desc4}</p>
                  <p>{elem.descriptionLvl?.rev4?.desc5}</p>
                  <p>{elem.descriptionLvl?.rev4?.desc6}</p>
                  <br />
                  <h2>{elem.descriptionLvl?.rev5?.heading}</h2>
                  <p>{elem.descriptionLvl?.rev5?.desc}</p>
                  <p>{elem.descriptionLvl?.rev5?.desc1}</p>
                  <p>{elem.descriptionLvl?.rev5?.desc2}</p>
                  <p>{elem.descriptionLvl?.rev5?.desc3}</p>
                  <p>{elem.descriptionLvl?.rev5?.desc4}</p>
                  <p>{elem.descriptionLvl?.rev5?.desc5}</p>
                  <p>{elem.descriptionLvl?.rev5?.desc6}</p>
                </> : null}
            </div>
        ))}
          <ReviewsList/>
          {/* {isAuthenticated && (
              <Reviews/>
          )} */}
          <Reviews/>
      </div>
    </section>
  )
}
