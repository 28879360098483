export const ROUTES_LINK = [
    {
        name: 'Home',
        path: '/',
        id: '1',
    },
    // {
    //     name: 'Product',
    //     path: '/products',
    //     id: '2',
    // },
    {
        name: 'Contact',
        path: '/contacts',
        id: '3',
    },
    {
        name: 'About Us',
        path: '/aboutus',
        id: '4',
    },
    {
        name: 'Reviews',
        path: '/blog',
        id: '5',
    },
]